import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { status } from './const';
import { get } from 'lodash';

const SeoCheckerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Keyword" source="keyword" resettable />
    <ReferenceInput
      label="Page"
      source="pageId"
      reference="pages"
      filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
    <SelectInput label="Status" source="status" choices={status} resettable />
  </Filter>
);

const SeoCheckerList = (props: any) => (
  <List
    title="SEO Checkers"
    exporter={false}
    filters={<SeoCheckerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>SEO Checkers</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <ReferenceField label="Customer" source="pageId" reference="pages">
        <ReferenceField source="customerId" reference="customers">
          <ReferenceField
            source="adminUserId"
            reference="users"
            link={record => `/customers/${record.id}/show`}
            emptyText="-">
            <TextField source="email" />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <TextField label="Keyword" source="keyword" />
      <ReferenceField
        source="countryId"
        reference="countries"
        emptyText="-"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Start Rank" source="rankStart" />
      <TextField label="Previous Rank" source="rankPrevious" />
      <TextField label="Current Rank" source="rankCurrent" />
      <TextField label="Google Page" source="googlePage" />
      <DateField
        label="Last Checked At"
        source="lastCheckedAt"
        showTime
        emptyText="-"
      />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default SeoCheckerList;
