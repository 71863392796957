import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  ReferenceField,
  UrlField,
  TextInput,
  SelectInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { status } from './const';
import { get } from 'lodash';

const PageCheckerFilter = (props: any) => (
  <Filter {...props}>
    {/* TODO: because pagechecker doen't have relation to customer anymore, i remove this filter <ReferenceInput
      label="Customer Email"
      source="customerId"
      reference="customers">
      <ReferenceInput
        source="adminUserId"
        reference="users"
        filterToQuery={searchText => ({ email: searchText })}>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </ReferenceInput> */}
    <TextInput label="URL" source="url" resettable />
    <ReferenceInput
      label="Name"
      source="pageId"
      reference="pages"
      filterToQuery={searchText => ({ name: searchText })}>
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
    <SelectInput label="Status" source="status" choices={status} resettable />
  </Filter>
);

const PageCheckerList = (props: any) => (
  <List
    title="Page Checkers"
    exporter={false}
    filters={<PageCheckerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Page Checkers</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Customer"
        source="page.customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}>
          <TextField source="email" />
        </ReferenceField>
      </ReferenceField>
      <TextField label="Tracked Text" source="trackedText" />
      <UrlField label="URL" source="url" />
      <TextField label="Page Name" source="page.name" />
      <DateField
        label="Last Checked At"
        source="lastCheckedAt"
        showTime
        emptyText="-"
      />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default PageCheckerList;
